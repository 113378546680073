import mixpanel from 'mixpanel-browser';

const shouldDebug = process.env.NODE_ENV === "development"

// TODO: move this key to an ENV variable
var mixpanelToken = 'development'
if (process.env.NODE_ENV === "production") {
    mixpanelToken = 'd7e440af2190abfd91a52de5b55ea110'
}

mixpanel.init(mixpanelToken, { debug: shouldDebug }); 