import mixpanel from 'mixpanel-browser';
import "../src/mixpanel_setup"

function setupClicks() {
    mixpanel.track_links("a", "Click Link")
}

document.addEventListener("DOMContentLoaded", function(event) {
    mixpanel.track('Page View')

    setupClicks()
})